.square-card {
    width: 300px;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.ant-card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
