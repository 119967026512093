.glass-container {
    background: rgba(102, 84, 84, 0.2); /* Semi-transparent white */
    backdrop-filter: blur(10px); /* Blur effect */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Shadow */
    padding: 20px;
    width: 30vw;
}

.auth-body {
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: rgb(116, 149, 202);
}

.lock-icon {
    font-size: 50px;
    color: white;
}
