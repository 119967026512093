.ag-theme-alpine {
    font-family: "Arial", sans-serif;
}

.ag-header-cell-label {
    color: #007bff;
    font-weight: bold;
}

.align-right {
    text-align: right;
}
